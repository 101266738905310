.wrapper {
    display: flex;
    width: 100%;
}

#sidebar {
    margin: 0;
    padding: 0;
    width: 13%;
    background-color: rgb(25, 135, 41) ;
    position: fixed;
    height: 100%;
    overflow: auto;
  }
  div h4 {
    padding: 40px 0 0 0;
    cursor: pointer;
    color: #fff;
    font-family :Georgia, 'Times New Roman', Times, serif
  }
  div a {
    font-size: 14px;
    color: rgba(255, 255, 255, 0.63);
    display: block;
    padding: 12px;
    padding-left: 0px;
    text-decoration: none;
    outline: none;
  }
  div a:hover {
    color: #56ff38;
    background: #fff;
    position: relative;
    background-color: #fff;
    border-top-left-radius: 22px;
    border-bottom-left-radius: 22px;
  }
   