.container {
  height: 100%;
  width: 73% ;
  justify-content : center ;
  align-items: center ;
  align-content: center ;
  border: solid ; 
}
.logo{
  size: 0px;
  width: 0px;
  height: 0px;
  padding-left: 10%;
}
